.LoginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.SSOOptions {
    margin-top: 10px;
}

.ErrorMessage {
    color: red;
    margin-bottom: 10px;
}
