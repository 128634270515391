.ChannelForm {
    padding: 15px;
}

.ChannelFormHeader {

}

.ChannelNameLabel {
    font-weight: bold;
    font-size: larger;
}

.ChannelTemplateName {
    color: grey;
}

.ChannelProps {
    margin: 5px;
}