.ChannelsPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ChannelList {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    min-width: 70vw;
    max-width: 70vw;
}

.ChannelListItem {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
}

.ChanneListItemHeader {
    display: flex;
}


.NewChannelButton {
    align-self: flex-end;
    margin: 5px;
    transform: scale(1.5);
}