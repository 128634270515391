.Welcome {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Content {
    max-width: 60vw;
    padding-bottom: 20px;
}