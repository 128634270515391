.TopicsPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

.TopicList {
    margin-top: 10px;
    min-width: 70vw;
    max-width: 70vw;
}

.TopicListItem {
    margin-top: 5px;
}

.GroupSearch {
    margin-top: 10px;
    min-width: 70vw;
    max-width: 70vw;
}